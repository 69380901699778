import React, { useState } from "react";
import style from "./place.module.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { handleRequestData } from "../../../store/hireChefSlice";
import { useNavigate } from "react-router-dom";

const PlaceSelection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedPlace, setSelectedPlace] = useState("");

  const handlePlaceChange = (event) => {
    setSelectedPlace(event.target.value);
  };
  const handleNext = () => {
    if (selectedPlace === "") {
      toast.error("select your place!");
    } else {
      dispatch(handleRequestData(["place", selectedPlace]));
      navigate("/date");
    }
  };

  return (
    <div className="p-8 flex flex-col gap-6 items-center justify-center">
      <h3 className="text-2xl font-bold mb-6 text-center">
        Select a Place for the Chef to Cook
      </h3>
      <div className="flex md:flex-col flex-row justify-center md:justify-between shadow-md rounded-md max-w-4xl">
        <div className="flex flex-col gap-3 md:w-full w-[50%] p-4">
          <div
            className={`flex gap-4 items-center p-4 rounded-lg cursor-pointer ${
              selectedPlace === "Garden" ? "bg-green-100" : "bg-white"
            } hover:bg-green-50 shadow`}
          >
            <input
              type="radio"
              id="garden"
              name="place"
              value="Garden"
              checked={selectedPlace === "Garden"}
              onChange={handlePlaceChange}
              className="form-radio text-green-600 focus:ring-green-500"
            />
            <label
              htmlFor="garden"
              className="text-lg font-medium text-gray-600"
            >
              Garden
            </label>
          </div>

          <div
            className={`flex gap-4 items-center p-4 rounded-lg cursor-pointer ${
              selectedPlace === "Banquet" ? "bg-yellow-100" : "bg-white"
            } hover:bg-yellow-50 shadow`}
          >
            <input
              type="radio"
              id="banquet"
              name="place"
              value="Banquet"
              checked={selectedPlace === "Banquet"}
              onChange={handlePlaceChange}
              className="form-radio text-yellow-600 focus:ring-yellow-500"
            />
            <label
              htmlFor="banquet"
              className="text-lg font-medium text-gray-600"
            >
              Banquet
            </label>
          </div>

          <div
            className={`flex gap-4 items-center p-4 rounded-lg cursor-pointer ${
              selectedPlace === "Farm House" ? "bg-blue-100" : "bg-white"
            } hover:bg-blue-50 shadow`}
          >
            <input
              type="radio"
              id="farmhouse"
              name="place"
              value="Farm House"
              checked={selectedPlace === "Farm House"}
              onChange={handlePlaceChange}
              className="form-radio text-blue-600 focus:ring-blue-500"
            />
            <label
              htmlFor="farmhouse"
              className="text-lg font-medium text-gray-600"
            >
              Farm House
            </label>
          </div>

          <div
            className={`flex gap-4 items-center p-4 rounded-lg cursor-pointer ${
              selectedPlace === "Beach" ? "bg-pink-100" : "bg-white"
            } hover:bg-pink-50 shadow`}
          >
            <input
              type="radio"
              id="beach"
              name="place"
              value="Beach"
              checked={selectedPlace === "Beach"}
              onChange={handlePlaceChange}
              className="form-radio text-pink-600 focus:ring-pink-500"
            />
            <label
              htmlFor="beach"
              className="text-lg font-medium text-gray-600"
            >
              Beach
            </label>
          </div>
        </div>

        {/* Second column */}
        <div className="flex flex-col gap-3 md:w-full w-[50%] p-4">
          <div
            className={`flex gap-4 items-center p-4 rounded-lg cursor-pointer ${
              selectedPlace === "Resort" ? "bg-purple-100" : "bg-white"
            } hover:bg-purple-50 shadow`}
          >
            <input
              type="radio"
              id="resort"
              name="place"
              value="Resort"
              checked={selectedPlace === "Resort"}
              onChange={handlePlaceChange}
              className="form-radio text-purple-600 focus:ring-purple-500"
            />
            <label
              htmlFor="resort"
              className="text-lg font-medium text-gray-600"
            >
              Resort
            </label>
          </div>

          <div
            className={`flex gap-4 items-center p-4 rounded-lg cursor-pointer ${
              selectedPlace === "Terrace" ? "bg-orange-100" : "bg-white"
            } hover:bg-orange-50 shadow`}
          >
            <input
              type="radio"
              id="terrace"
              name="place"
              value="Terrace"
              checked={selectedPlace === "Terrace"}
              onChange={handlePlaceChange}
              className="form-radio text-orange-600 focus:ring-orange-500"
            />
            <label
              htmlFor="terrace"
              className="text-lg font-medium text-gray-600"
            >
              Terrace
            </label>
          </div>

          <div
            className={`flex gap-4 items-center p-4 rounded-lg cursor-pointer ${
              selectedPlace === "Other" ? "bg-gray-100" : "bg-white"
            } hover:bg-gray-50 shadow`}
          >
            <input
              type="radio"
              id="other"
              name="place"
              value="Other"
              checked={selectedPlace === "Other"}
              onChange={handlePlaceChange}
              className="form-radio text-gray-600 focus:ring-gray-500"
            />
            <label
              htmlFor="other"
              className="text-lg font-medium text-gray-600"
            >
              Other
            </label>
          </div>
        </div>
      </div>

      <div className="flex gap-5 mt-5">
        <Link to="/location">
          <button className={style.prev}>Previous</button>
        </Link>
        <button onClick={handleNext} className={style.nxt}>
          Next
        </button>
      </div>

      <p className="mt-6 text-lg font-semibold text-gray-700 text-center">
        Selected Place: <span className="text-green-600">{selectedPlace}</span>
      </p>
    </div>
  );
};

export default PlaceSelection;
