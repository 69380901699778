import React, { useEffect, useState } from "react";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import styles from "./Date.module.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { handleRequestData } from "../../../store/hireChefSlice";

const Date = () => {
  const [selectedDates, setSelectedDates] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNext = () => {
    if (selectedDates.length === 0) {
      toast.error("select your event date!");
    } else {
      dispatch(handleRequestData(["dateRange", selectedDates]));
      navigate("/schedule/service");
    }
  };

  useEffect(() => {
    const isMobile = window.innerWidth <= 768;
    flatpickr("#dateRangePicker", {
      mode: "range",
      dateFormat: "M j, Y",
      defaultDate: ["2025-07-01", "2025-07-10"],
      showMonths: isMobile ? 1 : 3,
      onChange: (selectedDates) => {
        setSelectedDates(selectedDates);
      },
    });

    // Update flatpickr options on window resize
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768;
      flatpickr("#dateRangePicker", {
        showMonths: isMobile ? 1 : 3,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.div_container}>
      <div id="calendar-container" className={styles.calendarContainer}>
        <h2>When?</h2>
        <input
          type="text"
          id="dateRangePicker"
          placeholder="Select a date range"
          className={styles.dateRangePicker}
        />
      </div>
      <div className={styles.btn}>
        <Link to="/location">
          <button className={styles.prev}>Previous</button>
        </Link>
        <button onClick={handleNext} className={styles.nxt}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Date;
