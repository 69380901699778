import React, { useState } from "react";
import styles from "./foodRestriction.module.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const FoodRestriction = () => {
  const navigate = useNavigate();
  const [restriction, setRestriction] = useState("");
  const handleSubmit = () => {
    if (restriction === "") {
      toast.error("Select Restriction!");
    } else if (restriction === "none") {
      navigate("/restriction");
    } else {
      navigate("/select/restriction");
    }
  };
  return (
    <div
      style={{
        width: "100vw",
        height: "80vh",
      }} /* Changed to vw to make it responsive */
      className="flex items-center justify-center h-screen"
    >
      <div className="w-[60%] flex flex-col items-center gap-5 p-4 bg-white rounded shadow-md">
        <h2 className="font-semibold">Any food restrictions?</h2>
        <p>
          If you need to check it with your guests, no problem. You can inform
          your chef later.
        </p>
        <div className="flex gap-5 justify-center items-center">
          <div className="flex items-center gap-5 py-2 px-8 border border-orange-500 rounded-lg shadow-md hover:bg-orange-50">
            <input
              type="radio"
              id="none"
              onChange={(e) => setRestriction(e.target.value)}
              value="none"
              name="food-restrictions"
              className="form-radio text-orange-500"
            />
            <label htmlFor="none" className="text-lg font-medium">
              None
            </label>
          </div>
          <div className="flex items-center gap-5 py-2 px-8 border border-orange-500 rounded-lg shadow-md hover:bg-orange-50">
            <input
              type="radio"
              id="yes"
              onChange={(e) => setRestriction(e.target.value)}
              value="yes"
              name="food-restrictions"
              className="form-radio text-orange-500"
            />
            <label htmlFor="yes" className="text-lg font-medium">
              Yes
            </label>
          </div>
        </div>

        <div
          style={{
            background: "orange",
            padding: "0.5rem 1rem",
            borderRadius: "8px",
          }}
        >
          Not sure? You can change it later! 😉
        </div>
        <div className={styles.btn}>
          <Link to="/budget">
            <button className={styles.prev}>Previous</button>
          </Link>
          <button onClick={handleSubmit} className={styles.nxt}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default FoodRestriction;
