import React, { useState } from "react";
import styles from "./Restriction.module.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { handleRequestData } from "../../../store/hireChefSlice";

const Restriction = () => {
  const [restriction, setRestriction] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSelect = () => {
    if (restriction === "") {
      toast.error("write your restrictions!");
    } else {
      dispatch(handleRequestData(["restrictionMessage", restriction]));
      navigate("/summary");
    }
  };
  return (
    <div className={styles.div_container}>
      <div className={styles.heading}>
        <p>Any Restriction? Please mention it specifically..</p>
        <div className={styles.subheading}>
          If you need to check it with your guests, no problem. You can inform
          your chef later.
        </div>
      </div>
      <div className={styles.content}>
        <textarea
          onChange={(e) => setRestriction(e.target.value)}
          rows="20"
          cols="80"
          id=""
          placeholder="Write here..."
        ></textarea>
      </div>
      <div className={styles.notes}>
        <p>Not sure? You can change it later! 😉</p>
      </div>
      <div className={styles.btn}>
        <Link to="/food/restriction">
          <button className={styles.prev}>Previous</button>
        </Link>
        <button onClick={handleSelect} className={styles.nxt}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Restriction;
