import React, { useState } from "react";
import style from "./Location.module.css";
import { Link } from "react-router-dom";
import { FaLocationArrow } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { handleRequestData } from "../../../store/hireChefSlice";
import { toast } from "react-toastify";
import { FaArrowRight } from "react-icons/fa";

const Location = () => {
  const [searchInput, setSearchInput] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNext = () => {
    if (searchInput === "") {
      toast.error("select your location!");
    } else {
      dispatch(handleRequestData(["location", searchInput]));
      navigate("/date");
    }
  };

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;

        try {
          const response = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1`
          );
          // const response = await fetch(
          //   `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json&apiKey=pk.9432c2fb2d8b14ffa18cbb6050de3944`
          // );
          const data = await response.json();

          if (data && data.display_name) {
            setSearchInput(data.display_name);
          } else {
            alert("Unable to retrieve the address.");
          }
        } catch (error) {
          console.error("Error fetching location data:", error);
          alert("Error fetching location data.");
        }
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  return (
    <div className={style.div_container}>
      <h2>Where is the event?</h2>
      <p>Please ensure the accurate location is shared by you</p>
      <div className="w-full flex flex-col gap-5">
        <div className={`${style.inp_txt} w-full flex justify-center`}>
          <input
            type="text"
            id="locationSearch"
            placeholder="Enter the location"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>
        <div className="flex justify-center">
          <button
            type="button"
            onClick={handleGetCurrentLocation}
            className={style.locationBtn}
          >
            <span className="text-white">
              <FaLocationArrow />
            </span>
            <p className="text-white">Use My Current Location</p>
          </button>
        </div>
      </div>
      <div className="flex gap-5">
        <Link to="/occassion">
          <button className={style.prev}>Previous</button>
        </Link>
        <button onClick={handleNext} className={style.nxt}>
          Next
        </button>
      </div>
      <div className="mt-5 font-bold">Or</div>
      <div className="flex flex-wrap justify-center items-center gap-5 mt-5">
        <div className="text-lg flex items-center gap-2">
          <span>
            <FaArrowRight />
          </span>
          <span>Do you want us to find a location for your event!</span>
        </div>
        <button onClick={() => navigate("/place")} className={style.yes_btn}>
          Yes
        </button>
      </div>
    </div>
  );
};

export default Location;
