import React, { useState, useEffect } from "react";
import styles from "./Scheduleservice.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { handleRequestData } from "../../../store/hireChefSlice";
import { FaCheckCircle, FaRegCircle } from "react-icons/fa"; // Example icons

const Scheduleservice = () => {
  const { requestData } = useSelector((state) => state.chefSlice);
  const [mealData, setMealData] = useState({});
  console.log("mealData", mealData);
  const [forWhat, setForwhat] = useState("");
  const [dates, setDates] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (requestData.dateRange.length > 0) {
      const data = generateDateRange(
        requestData.dateRange[0],
        requestData.dateRange[1]
      );
      setDates(data);

      // Initialize all meals as selected for each date
      const initialMealData = data.reduce((acc, date) => {
        acc[date] = {
          Breakfast: { selected: true },
          Lunch: { selected: true },
          Dinner: { selected: true },
          Evening_Snacks: { selected: true },
          Input: "",
        };
        return acc;
      }, {});
      setMealData(initialMealData);
    }
  }, [requestData.dateRange]);

  const generateDateRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate).toISOString().split("T")[0]); // Use ISO format for consistency
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  const formatDate = (date) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const toggleSelection = (date, mealType) => {
    setMealData((prev) => ({
      ...prev,
      [date]: {
        ...prev[date],
        [mealType]: {
          ...prev[date][mealType],
          selected: !prev[date][mealType].selected,
        },
      },
    }));
  };

  const handleInputChange = (date, value) => {
    setMealData((prev) => ({
      ...prev,
      [date]: {
        ...prev[date],
        Input: value,
      },
    }));
  };

  const isNextButtonDisabled = () => {
    return !Object.values(mealData).some((meal) =>
      Object.values(meal).some((item) => item.selected)
    );
  };

  const handleNext = () => {
    if (isNextButtonDisabled()) {
      toast.error("Select at least one meal!");
    } else {
      dispatch(handleRequestData(["mealData", mealData]));
      navigate("/count/people");
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.div_container}>
        <h2>We want to schedule my services for...</h2>
        <table className={styles.scheduleTable}>
          <thead>
            <tr>
              <th>DATE</th>
              <th>BREAKFAST</th>
              <th>LUNCH</th>
              <th>DINNER</th>
              <th>Evening Snacks</th>
              <th>Additional Input</th> {/* New column */}
            </tr>
          </thead>
          <tbody>
            {dates.map((date, index) => (
              <tr key={index}>
                <td>{formatDate(date)}</td>
                {["Breakfast", "Lunch", "Dinner", "Evening_Snacks"].map(
                  (mealType) => (
                    <td key={mealType}>
                      <div>
                        {mealData[date]?.[mealType].selected ? (
                          <FaCheckCircle
                            className={styles.icon}
                            onClick={() => toggleSelection(date, mealType)}
                          />
                        ) : (
                          <FaRegCircle
                            className={styles.icon}
                            onClick={() => toggleSelection(date, mealType)}
                          />
                        )}
                      </div>
                    </td>
                  )
                )}
                <td>
                  <input
                    style={{ height: "26px", outline: "none" }}
                    type="text"
                    value={mealData[date]?.Input || ""}
                    onChange={(e) => handleInputChange(date, e.target.value)}
                    placeholder="Additional info"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.note}>
          Uncheck the meals you don't need. Swipe down the calendar to see all
          the dates. 😉
        </div>
        <div className={styles.btn}>
          <Link to="/date">
            <button className={styles.prev}>Previous</button>
          </Link>
          <button
            onClick={handleNext}
            className={styles.nxt}
            disabled={isNextButtonDisabled()}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Scheduleservice;
