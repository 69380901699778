import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import styles from "./Navbar.module.css";
import { Link, NavLink } from "react-router-dom";
import { SocialMedia } from "../SocialMedia/SocialMedia";

export const menuItems = [
  { label: "Home", link: "/" },
  { label: "Menu", link: "menu-list/" },
];

export const Navbar = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const [activeItem, setActiveItem] = useState(0);

  const handleItemClick = (index) => {
    setActiveItem(index);
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const closeSidebar = () => {
    setShowSidebar(false);
  };
  return (
    <>
      <div className="w-[100%] border-bottom mb-[100px] ">
        <nav className="bg-white shadow-md max-sm:shadow-lg shadow-gray-20 w-full fixed top-0 z-50 ">
          <div className="max-sm:flex sm:flex max-sm:justify-between sm:justify-between">
            <div className="flex md:hidden sm:w-0 max-sm:bg-white max-sm:justify-center max-sm:items-center px-2">
              <button
                onClick={toggleSidebar}
                className="text-white max-sm:text-black"
              >
                <svg
                  className="w-6 h-6 text-4xl"
                  fill="none"
                  stroke="currentColor"
                  viewBox="-0 5 20 20"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="flex justify-between container mx-auto">
              <div class="md:order-1 max-sm:w-full sm:w-full p-2 flex items-center max-sm:bg-white">
                <Link to="/">
                  <img className="h-[50px] w-[80px]" src={logo} alt="logo" />
                </Link>
              </div>
              <div className="md:order-2 items-center justify-end logobg sm:w-full hidden sm:block px-5">
                <div className="flex items-center justify-center h-full">
                  <div className="flex flex-col items-center">
                    <ul className="flex flex-col items-center  sm:flex-row  md:gap-10 max-sm:gap-4 sm:gap-4">
                      {menuItems.map((menuItem, index) => (
                        <li key={index}>
                          {menuItem.dropdown ? (
                            <div
                              onMouseEnter={() => setActiveItem(index)}
                              onMouseLeave={() => setActiveItem(null)}
                              className="relative"
                            >
                              <p className="text-gray-900 text-lg cursor-pointer">
                                {menuItem.label}
                              </p>
                              {activeItem === index && (
                                <ul className="absolute top-full  w-[240px] left-0 bg-gray-300 shadow-lg rounded-md py-2 px-4">
                                  {menuItem.dropdown.map((item, i) => (
                                    <li
                                      key={i}
                                      className="text-gray-900 hover:text-orange-500 text-lg"
                                    >
                                      <NavLink
                                        to={item.link}
                                        activeClassName="active"
                                        onClick={() => handleItemClick(index)}
                                      >
                                        {item.label}
                                      </NavLink>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          ) : (
                            <NavLink
                              to={menuItem.link}
                              activeClassName="active"
                              onClick={() => handleItemClick(index)}
                            >
                              <p
                                className={`text-gray-900 text-xl hover:text-orange-500 ${
                                  activeItem === index ? "text-orange-500 " : ""
                                }`}
                              >
                                {menuItem.label}
                              </p>
                            </NavLink>
                          )}
                        </li>
                      ))}
                      <SocialMedia />
                      <Link to="/hire-chef">
                        <button className={styles.hireChefMianBtn}>
                          Hire a chef
                        </button>
                      </Link>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      {showSidebar && (
        <div className="fixed inset-0 bg-blue-900  text-white z-50 w-[200px] p-4">
          <div className="flex p-1 justify-between">
            <div>
              <img className="h-[6  0px] w-[100px]" src={logo} alt="logo" />
            </div>
            <div>
              <button
                onClick={closeSidebar}
                className="text-white bg-gray-500 rounded p-1"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          {/* sidebar */}
          <Sidebar />
        </div>
      )}
    </>
  );
};

export const Sidebar = () => {
  return (
    <div className="px-2 pt-2 text-xl font-bold">
      <Link to="/">
        <p className="mt-5">Home</p>
      </Link>
      <Link to="/menu-list">
        <p className="mt-5">Menu</p>
      </Link>
      <SocialMedia />
      {/* hire schef button */}
      <Link to="/hire-chef">
        <button className={styles.hireChefMianBtn}>Hire a chef</button>
      </Link>
      {/* hire chef button */}
    </div>
  );
};
