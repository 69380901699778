import React, { useState } from "react";
import styles from "./Countpeople.module.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { handleRequestData } from "../../../store/hireChefSlice";

const Countpeople = () => {
  const [peopleCount, setPeopleCount] = useState({
    adultCount: 0,
    teensCount: 0,
    childrenCount: 0,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNext = () => {
    if (
      peopleCount.adultCount === 0 &&
      peopleCount.teensCount === 0 &&
      peopleCount.childrenCount === 0
    ) {
      toast.error("select your people!");
    } else {
      dispatch(handleRequestData(["ageCountPeople", peopleCount]));
      navigate("/budget");
    }
  };
  return (
    <div className={styles.mainContainer}>
      <div className={styles.div_container}>
        <h2>We are...</h2>
        <div className={styles.counter_group}>
          <h3>Adults</h3>
          <p className={styles.subtext}>Over 16 years of age</p>
          <div className={styles.counter_controls}>
            <button
              className={styles.counter_controls_button}
              onClick={() => {
                if (peopleCount.adultCount > 0) {
                  setPeopleCount({
                    ...peopleCount,
                    ["adultCount"]: peopleCount.adultCount - 1,
                  });
                }
              }}
              id="decreaseAdults"
            >
              −
            </button>
            <span id="adultsCount">{peopleCount.adultCount}</span>
            <button
              className={styles.counter_controls_button}
              onClick={() =>
                setPeopleCount({
                  ...peopleCount,
                  ["adultCount"]: peopleCount.adultCount + 1,
                })
              }
              id="increaseAdults"
            >
              +
            </button>
          </div>
        </div>

        <div className={styles.counter_group}>
          <h3>Teens</h3>
          <p className={styles.subtext}>12 - 15 years of age</p>
          <div className={styles.counter_controls}>
            <button
              className={styles.counter_controls_button}
              onClick={() => {
                if (peopleCount.teensCount > 0) {
                  setPeopleCount({
                    ...peopleCount,
                    ["teensCount"]: peopleCount.teensCount - 1,
                  });
                }
              }}
              id="decreaseTeens"
            >
              −
            </button>
            <span id="teensCount">{peopleCount.teensCount}</span>
            <button
              className={styles.counter_controls_button}
              onClick={() =>
                setPeopleCount({
                  ...peopleCount,
                  ["teensCount"]: peopleCount.teensCount + 1,
                })
              }
              id="increaseTeens"
            >
              +
            </button>
          </div>
        </div>

        <div className={styles.counter_group}>
          <h3>Children</h3>
          <p className={styles.subtext}>2 - 11 years of age</p>
          <div className={styles.counter_controls}>
            <button
              className={styles.counter_controls_button}
              onClick={() => {
                if (peopleCount.childrenCount > 0) {
                  setPeopleCount({
                    ...peopleCount,
                    ["childrenCount"]: peopleCount.childrenCount - 1,
                  });
                }
              }}
              id="decreaseChildren"
            >
              −
            </button>
            <span id="childrenCount">{peopleCount.childrenCount}</span>
            <button
              className={styles.counter_controls_button}
              onClick={() =>
                setPeopleCount({
                  ...peopleCount,
                  ["childrenCount"]: peopleCount.childrenCount + 1,
                })
              }
              id="increaseChildren"
            >
              +
            </button>
          </div>
        </div>
        <div className={styles.btn}>
          <Link to="/schedule/service">
            <button className={styles.prev}>Previous</button>
          </Link>
          <button onClick={handleNext} className={styles.nxt}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Countpeople;
