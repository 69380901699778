import React, { useState } from "react";
import styles from "./Summary.module.css";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const hearValue = [
  "Someone recommended",
  "Google",
  "Bing",
  "Instagram",
  "LinkedIn",
  "Facebook",
  "I was at a dinner party",
  "Other",
];

const Summary = () => {
  const userQuery = useSelector((state) => state.chefSlice.requestData);
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    userName: "",
    userEmail: "",
    userContact: "",
    hearForm: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      userData.userName === "" ||
      userData.userEmail === "" ||
      userData.userContact === "" ||
      userData.hearForm === ""
    ) {
      toast.error("All fields are required!");
      return;
    }

    const requestData = {
      to_name: "Tingle Taste", //
      serviceType: userQuery.serviceType, //
      occassionType: userQuery.occassionType, //
      location: userQuery.location, //
      dateRange: userQuery.dateRange
        .map((date) => date.toLocaleDateString())
        .join(" to "), //
      forWhat: userQuery.forWhat, //
      adultCount: userQuery.ageCountPeople.adultCount, //
      teensCount: userQuery.ageCountPeople.teensCount, //
      childrenCount: userQuery.ageCountPeople.childrenCount, //
      budgetType: userQuery.budgetType, //
      restrictionMessage: userQuery.restrictionMessage, //
      userName: userData.userName, //
      userEmail: userData.userEmail, //
      userContact: userData.userContact, //
      hearForm: userData.hearForm, //
    };

    try {
      const response = await axios.post("https://api.web3forms.com/submit", {
        access_key: "6d2031d1-5b59-42f1-ab9c-504a94cd4bc4",
        ...requestData,
      });
      if (response) {
        toast.success("request sent successfully!");
        navigate("/");
      }
    } catch (error) {
      console.error("Unexpected error occurred: ", error);
      toast.error("An unexpected error occurred.");
    }
  };

  return (
    <div className={styles.form_container}>
      <div className="flex flex-col w-[50%]">
        <form className="flex flex-col gap-5">
          <p className="text-3xl">That’s it!</p>
          <p>
            Now, just add your contact info, and we’ll send you personalized
            menu proposals for free in less than 20 minutes.
          </p>
          <div className="flex flex-col">
            <label htmlFor="name">Name</label>
            <input
              className="border py-1 px-2 outline-none rounded"
              type="text"
              value={userData.userName}
              placeholder="John Doe"
              onChange={(e) =>
                setUserData({ ...userData, userName: e.target.value })
              }
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="email">Email</label>
            <input
              className="border py-1 px-2 outline-none rounded"
              type="email"
              value={userData.userEmail}
              placeholder="example@gmail.com"
              onChange={(e) =>
                setUserData({ ...userData, userEmail: e.target.value })
              }
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="phone">Phone</label>
            <PhoneInput
              country={"in"}
              value={userData.userContact}
              onChange={(phone) =>
                setUserData({ ...userData, userContact: phone })
              }
              inputStyle={{ width: "100%" }}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="about">How did you hear about us?</label>
            <select
              onChange={(e) =>
                setUserData({ ...userData, hearForm: e.target.value })
              }
              className="py-2 px-2 outline-none rounded"
              value={userData.hearForm}
            >
              <option value="">Select an option</option>
              {hearValue.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </form>
      </div>
      <div className={styles.btn}>
        <Link to="/restriction">
          <button className={styles.prev}>Previous</button>
        </Link>
        <button onClick={handleSubmit} className={styles.nxt}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Summary;
