import React, { useState } from "react";
import style from "./Multiple.module.css";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { handleRequestData } from "../../../store/hireChefSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Multiple = () => {
  const navigate = useNavigate();
  const [occassionType, setOccassionType] = useState("");
  const dispatch = useDispatch();
  const handleOccassion = (e) => {
    setOccassionType(e.target.value);
  };
  const handleNext = () => {
    if (occassionType === "") {
      toast.error("select your occassion type!");
    } else {
      dispatch(handleRequestData(["occassionType", occassionType]));
      navigate("/location");
    }
  };
  return (
    <div className={style.div_container}>
      <div>
        <h2>What's the occasion?</h2>
        <p className={style.subheading}>
          Define your event clearly so that we can set the perfect tone and vibe
          for the event!
        </p>
      </div>
      <form action="">
        <div className={style.label_wrap}>
          <div className={style.input_wrap}>
            <div className={style.input}>
              <label for="">Family Reunion</label>
              <input
                onChange={handleOccassion}
                type="radio"
                name="occasion"
                value="Family Reunion"
              />
            </div>
            <div className={style.input}>
              <label for="">Gathering</label>
              <input
                onChange={handleOccassion}
                type="radio"
                name="occasion"
                value="Gathering"
              />
            </div>
          </div>
          <div className={style.input_wrap}>
            <div className={style.input}>
              <label for="">Birthday</label>
              <input
                onChange={handleOccassion}
                type="radio"
                name="occasion"
                value="Birthday"
              />
            </div>
            <div className={style.input}>
              <label for="">Wedding</label>
              <input
                onChange={handleOccassion}
                type="radio"
                name="occasion"
                value="Wedding"
              />
            </div>
          </div>
          <div className={style.input_wrap}>
            <div className={style.input}>
              <label for="">Corporate</label>
              <input
                onChange={handleOccassion}
                type="radio"
                name="occasion"
                value="Corporate"
              />
            </div>
            <div className={style.input}>
              <label for="">Others</label>
              <input
                onChange={handleOccassion}
                type="radio"
                name="occasion"
                value="Others"
              />
            </div>
          </div>
        </div>
      </form>
      <div className="btn">
        <Link to="/hire-chef">
          <button className={style.prev}>Previous</button>
        </Link>
        <button onClick={handleNext} className={style.nxt}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Multiple;
