import React from "react";
import style from "./TakechefService.module.css";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { handleRequestData } from "../../../store/hireChefSlice";

const TakechefService = () => {
  const dispatch = useDispatch();
  return (
    <div className={style.div_container}>
      <div>
        <h2 className="text-center">
          <b>Please specify the type of service you need ?</b>
        </h2>
        <p className={style.subheading}>
          Define your event to see availability of chefs, menus and prices
          accordingly. This will take less than 2 minutes to complete!
        </p>
      </div>
      <div className={style.chefservice_div}>
        <div className={`${style.chefservice_div1} shadow-md`}>
          <h3>Single Service</h3>
          <p>A single experience to always remember</p>
          <div className="flex gap-5 mt-3">
            <Link to="/occassion">
              <button
                onClick={() =>
                  dispatch(handleRequestData(["serviceType", "Single Service"]))
                }
              >
                Select
              </button>
            </Link>
            <Link className={style.back} to="/">
              Back
            </Link>
          </div>
        </div>
        <div className={`${style.chefservice_div1} shadow-md`}>
          <h3>Multiple Services</h3>
          <p>Ideal for holidays, Multiple gathering and meals</p>
          <div className="flex gap-5 mt-3">
            <Link to="/multiple">
              <button
                onClick={() =>
                  dispatch(handleRequestData(["serviceType", "Single Service"]))
                }
              >
                Select
              </button>
            </Link>
            <Link className={style.back} to="/">
              Back
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TakechefService;
