import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Navbar } from "../Components/Navbar/Navbar";
import { Home } from "../Pages/HomeLayouts/home";
import { About } from "../Pages/About";
import { Footer } from "../Components/Footer/Footer";
import { Menu } from "../Components/MenuList/Menu";
import TakechefService from "../Components/HireChef/Takeservice/TakechefService";
import Occassion from "../Components/HireChef/occassion/Occassion";
import Location from "../Components/HireChef/Location/Location";
import Date from "../Components/HireChef/Date/Date";
import Scheduleservice from "../Components/HireChef/ScheduleService/Scheduleservice";
import Countpeople from "../Components/HireChef/CountPeople/Countpeople";
import Budget from "../Components/HireChef/Budget/Budget";
import Restriction from "../Components/HireChef/Restriction/Restriction";
import Summary from "../Components/HireChef/Summary/Summary";
import Multiple from "../Components/HireChef/Multiple/Multiple";
import Place from "../Components/HireChef/SelectPlace/Place";
import FoodRestriction from "../Components/HireChef/food_restriction/FoodRestriction";
import SelectRestriction from "../Components/HireChef/SelectRestriction/SelectRestriction";

const paths = ["/", "/home", "/menu-list/"];

const UserRoute = () => {
  return (
    <Router>
      <LayoutWithLocation />
    </Router>
  );
};

const LayoutWithLocation = () => {
  const location = useLocation();

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/menu-list" element={<Menu />} />
        <Route path="/hire-chef" element={<TakechefService />} />
        <Route path="/occassion" element={<Occassion />} />
        <Route path="/location" element={<Location />} />
        <Route path="/date" element={<Date />} />
        <Route path="/schedule/service" element={<Scheduleservice />} />
        <Route path="/count/people" element={<Countpeople />} />
        <Route path="/budget" element={<Budget />} />
        <Route path="/restriction" element={<Restriction />} />
        <Route path="/summary" element={<Summary />} />
        <Route path="/multiple" element={<Multiple />} />
        <Route path="/place" element={<Place />} />
        <Route path="/food/restriction" element={<FoodRestriction />} />
        <Route path="/select/restriction" element={<SelectRestriction />} />
      </Routes>
      {paths.includes(location.pathname) && <Footer />}
    </>
  );
};

export default UserRoute;
