import React, { useState } from "react";
import styles from "../food_restriction/foodRestriction.module.css";
import style from "./select.module.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { handleRequestData } from "../../../store/hireChefSlice";

const SelectRestriction = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [restrictions, setRestrictions] = useState([]);
  const [restrictionValue, setRestrictionValue] = useState("");

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setRestrictions((prev) =>
      checked ? [...prev, value] : prev.filter((item) => item !== value)
    );
  };

  const handleSubmit = () => {
    if (restrictions.length === 0) {
      toast.error("Select at least one restriction!");
    } else {
      dispatch(handleRequestData(["restrictions", restrictions]));
      navigate("/restriction");
    }
  };

  const handleInput = (e) => {
    setRestrictionValue(e.target.value);
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "80vh",
      }}
      className="flex items-center justify-center h-screen"
    >
      <div className="w-[60%] flex flex-col items-center gap-5 p-4 bg-white rounded shadow-md">
        <h2 className="font-semibold">Select all that apply</h2>
        <p>
          With this info, our chefs will craft customized menus tailored to your
          needs.
        </p>
        <div
          style={{ maxWidth: "40rem", flexWrap: "wrap" }}
          className="flex gap-5 justify-center items-center"
        >
          {[
            { label: "Vegetarian", value: "vegetarian" },
            { label: "Gluten", value: "gluten" },
            { label: "Nuts", value: "nuts" },
            { label: "Shellfish", value: "shellfish" },
            { label: "Dairy products", value: "dairy products" },
          ].map((item) => (
            <div
              key={item.value}
              className="flex items-center gap-5 py-2 px-8 border border-orange-500 rounded-lg shadow-md hover:bg-orange-50"
            >
              <input
                type="checkbox"
                id={item.value}
                onChange={handleCheckboxChange}
                value={item.value}
                name="food-restrictions"
                className="form-checkbox text-orange-500"
              />
              <label htmlFor={item.value} className="text-lg font-medium">
                {item.label}
              </label>
            </div>
          ))}
        </div>

        <div style={{ flexDirection: "column" }} className={styles.btn}>
          <textarea
            cols="40"
            className={style.inputField}
            value={restrictionValue}
            onChange={handleInput}
            placeholder="One of my friends is allergic to garlic and..."
          />
          <div className={styles.btn}>
            <Link to="/food/restriction">
              <button className={styles.prev}>Previous</button>
            </Link>
            <button onClick={handleSubmit} className={styles.nxt}>
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectRestriction;
