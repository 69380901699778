import React, { useState } from "react";
import styles from "./Budget.module.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleRequestData } from "../../../store/hireChefSlice";

const Budget = () => {
  const { requestData } = useSelector((state) => state.chefSlice);
  const [budget, setBudget] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSelect = () => {
    if (budget === "") {
      toast.error("select the budget type!");
    } else {
      dispatch(handleRequestData(["budgetType", budget]));
      navigate("/food/restriction");
    }
  };
  // Calculate total meals
  const calculateTotalMeals = () => {
    return Object.values(requestData.mealData).reduce((total, meals) => {
      return total + Object.values(meals).filter(Boolean).length;
    }, 0);
  };
  const formatDate = (date) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };
  const startDate = formatDate(requestData.dateRange[0]);
  const endDate = formatDate(requestData.dateRange[1]);
  const totalMeals = calculateTotalMeals();

  return (
    <div classNameName={styles.mainContainer}>
      <div className={styles.div_container}>
        <h1>What's your budget for this experience?</h1>
        <div className={styles.options}>
          <div className={`${styles.option} shadow-md`}>
            <div className="flex justify-between">
              <span>Casual</span>
              <input
                onChange={(e) => setBudget(e.target.value)}
                type="radio"
                id="casual"
                name="budget"
                value="casual"
              />
            </div>
            <label for="casual">
              <p>Build connections around great food</p>
              <div className={styles.price}>
                You will receive the quotation on your email
              </div>
            </label>
          </div>
          <div className={`${styles.option} shadow-md`}>
            <div className="flex justify-between">
              <span>Gourmet</span>
              <input
                onChange={(e) => setBudget(e.target.value)}
                type="radio"
                id="gourmet"
                name="budget"
                value="gourmet"
              />
            </div>
            <label for="gourmet">
              <p>Brilliant menus to impress your guests</p>
              <div className={styles.price}>
                You will receive the quotation on your email
              </div>
            </label>
          </div>
          <div className={`${styles.option} shadow-md`}>
            <div className="flex justify-between">
              <span>Chef's table</span>
              <input
                onChange={(e) => setBudget(e.target.value)}
                type="radio"
                id="chefs-table"
                name="budget"
                value="chefs-table"
              />
            </div>
            <label for="chefs-table">
              <p>The best of the best for your event</p>
              <div className={styles.price}>
                You will receive the quotation on your email
              </div>
            </label>
          </div>
        </div>
        <div className={styles.notes}>
          <p>
            <b>* </b>Chef’s fee for services between {startDate} and {endDate}{" "}
            for a total of {totalMeals} meals.
          </p>
          <p>
            <b>** </b>This price does not include the cost of groceries, which
            should be paid directly to the chef on-site. Receipts will be
            presented on a daily basis.
          </p>
          <p>
            <b>***</b> Each meal starting at ₹3,367 per person.
          </p>
        </div>
        <div className={styles.btn}>
          <Link to="/count/people">
            <button className={styles.prev}>Previous</button>
          </Link>
          <button onClick={handleSelect} className={styles.nxt}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Budget;
