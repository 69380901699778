import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requestData: {
    to_name: "Tingle Taste",
    serviceType: "",
    occassionType: "",
    location: "",
    dateRange: [],
    forWhat: "",
    ageCountPeople: {},
    budgetType: "",
    restrictionMessage: "",
    userData: {},
    place: "",
    mealData: {},
    restrictions: [],
  },
};

export const hireChefSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    handleRequestData: (state, action) => {
      state.requestData = {
        ...state.requestData,
        [action.payload[0]]: action.payload[1],
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { handleRequestData } = hireChefSlice.actions;

export default hireChefSlice.reducer;
